<template>
<div class="html-agreement">
  <div class="container">
    <div v-if="!isApp" class="agreement-title">{{$t('user.privacy_title')}}</div>
    <div v-html="$t('user.privacy')"></div>
  </div>
</div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['isApp']),
  },
}
</script>